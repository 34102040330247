import { pickBy } from "ramda"

import { getAllGateValuesForLogging } from "gather-browser-common/dist/src/public/gatekeeping/gate"
import { Space } from "gather-game-logic/dist/src/public/models/Space"

export const getTrueGates = (userId: string | null, spaceId: string | null, space?: Space) => {
  const gates = getAllGateValuesForLogging(
    userId,
    spaceId,
    // If the current space info isn't defined, we can't determine the space creation date,
    // so we pass in "NOT_AVAILABLE" to exclude gates that rely on this value.
    space?.createdAt_ALWAYS_UNDEFINED ?? "NOT_AVAILABLE",
  )
  return Object.keys(pickBy((gateVal) => gateVal, gates))
}
