import { DateTime } from "luxon"

import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
} from "gather-browser-common/dist/src/public/userAgent"
import { getTrueGates } from "src/utils/gateLoggingUtils"

export const PAGE_LOAD_TIME = DateTime.now()
const PAGE_LOAD_TIME_ISO = PAGE_LOAD_TIME.toISO()

export const getBrowserRelease = async (userId: string | null, spaceId: string | null) =>
  await tsrAPI.releases.browser.get({
    params: {
      browserName,
      releaseCommit: COMMIT_HASH,
      releaseChannel: "latest",
    },
    body: {
      osName,
      osVersion,
      browserVersion,

      buildTimestamp: BUILD_TIMESTAMP,
      pageLoadTimestamp: PAGE_LOAD_TIME_ISO,

      userId,
      spaceId,
      gates: getTrueGates(userId, spaceId),
    },
  })
