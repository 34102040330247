import { tsrAPI } from "gather-browser-common/dist/src/public/tsRestClient"
import { RegisterUserResponse } from "gather-common/dist/src/public/v2/resources/users"
import { UserUpdateBody } from "gather-http-common/dist/src/public/contracts/users"
import { DEFAULT_PATH_PARAM_USER } from "gather-http-common/dist/src/public/httpAPI"
import { UserAccountPrisma } from "gather-prisma-types/dist/src/public/client"

export const getUserAccount = async (spaceId: string | null): Promise<RegisterUserResponse> => {
  // NOTE: this is a redundant variable, but we pull it out to then run a TS benchmark on it.
  // Do not refactor to inline.
  // noinspection UnnecessaryLocalVariableJS
  const res = await tsrAPI.users.get({
    params: { userId: DEFAULT_PATH_PARAM_USER },
    query: { spaceId: spaceId },
  })
  return res
}

export const updateUserData = async (body: UserUpdateBody): Promise<UserAccountPrisma> =>
  await tsrAPI.users.update({ params: { userId: DEFAULT_PATH_PARAM_USER }, body })
