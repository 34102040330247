import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils"
import { theme } from "gather-design-foundations"
import { logoContainerStyle, logoStyle } from "src/components/ui/GrapesLogo.css"
import Grapes from "src/images/grapes.svg"

export const GrapesLogo = observer(function GrapesLogo() {
  return (
    <div className={logoContainerStyle}>
      <div className={logoStyle}>
        <Grapes color={theme.dangerouslyStatic.alphaWhite100} height={38} />
      </div>
    </div>
  )
})
