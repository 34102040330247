import { memo } from "react"

import { ThemeProvider } from "gather-design-system"
import { LoadingScreen } from "src/components/ui/LoadingScreen"
import { Translated } from "src/i18n/t"
import { LoadingFullScreenContainer } from "src/routes/LoadingFullScreenContainer"
import { uiConfigClass } from "src/uiConfig.css"

type Props = {
  loadingText?: Translated

  // We show this component at the beginning of the app's loading sequence before we have Repos,
  // Services, Redux, etc. Because of this, we can't yet render a DesktopToolbar component, but we
  // may still want to pretend one is there to prevent content from shifting when a toolbar is
  // present. Set preserveSpaceForDesktopToolbar to true to preserve this space on desktop.
  preserveSpaceForDesktopToolbar?: boolean
}

export const RootRouteLoadingFallback = memo(function RootRouteLoadingFallback({
  loadingText,
  preserveSpaceForDesktopToolbar = false,
}: Props) {
  return (
    <ThemeProvider theme="light" className={uiConfigClass}>
      <LoadingFullScreenContainer preserveSpaceForDesktopToolbar={preserveSpaceForDesktopToolbar}>
        <LoadingScreen loadingText={loadingText} loadingTextMinHeight={46} />
      </LoadingFullScreenContainer>
    </ThemeProvider>
  )
})

export const RootRouteLoadingFallbackWithToolbar = memo(
  function RootRouteLoadingFallbackWithToolbar({ loadingText }: Props) {
    return <RootRouteLoadingFallback loadingText={loadingText} preserveSpaceForDesktopToolbar />
  },
)
