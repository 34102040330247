import { ClientType } from "gather-common/dist/src/public/constants"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"

export const isRecordingMode = () => {
  const query = new URLSearchParams(window.location.search)
  return query.get("clientType") === ClientType.Recorder
}

export const userIsRecordingClient_NOT_IMPLEMENTED = (_userId: Uuid) =>
  // we don't have any recording clients right now, this needs to be implemented
  false
