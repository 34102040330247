import { DateTime, Interval } from "luxon"

import { lazily } from "gather-common-including-video/dist/src/public/fpHelpers"
import { GatherEventEmitter } from "gather-common-including-video/dist/src/public/GatherEventEmitter"
import { DesktopUpdateInfo } from "gather-electron-interop/dist/src/public/OptionalInterop"
import {
  BrowserUpdateInfo,
  ClientReleasePriority,
  isDefaultClientUpdate,
  isDismissableClientUpdate,
  isNotifiedClientUpdate,
} from "gather-http-common/dist/src/public/clientReleases"
import { PAGE_LOAD_TIME } from "src/api/releases"
import ElectronInterop from "src/ElectronInterop"
import { Logger } from "src/utils/Logger"
import { Repos } from "../repositories/Repos"

// How long we should give the user to see our critical update notification before we force refresh
export const CRITICAL_NOTIF_TIME_MS = 10 * 60 * 1000 // 10 minutes

// Check if it's definitely not working hours, so it's reasonable to refresh the user
const isOutsideWorkingHours = () => {
  const now = DateTime.local()

  // Very conservatively use 2-6am as "not working hours"
  const start = DateTime.local().set({ hour: 2, minute: 0, second: 0 })
  const end = DateTime.local().set({ hour: 6, minute: 0, second: 0 })

  return Interval.fromDateTimes(start, end).contains(now)
}

const canApplyUpdateImmediately = (level?: ClientReleasePriority) => {
  if (level !== ClientReleasePriority.MEDIUM) return false

  return !isInSpace() || isOutsideWorkingHours()
}

const isInSpace = () => Repos.gameSpace.spaceLoaded

export enum ClientReleaseEvent {
  BrowserUpdate = "BrowserUpdate",
  DesktopUpdate = "DesktopUpdate",
}

type ClientReleaseEventDataMap = {
  [ClientReleaseEvent.BrowserUpdate]: [BrowserUpdateInfo]
  [ClientReleaseEvent.DesktopUpdate]: [DesktopUpdateInfo]
}

/*
 * ClientReleasesService listens for client updates (browser or electron) and then refreshes / reloads the client based
 * on the severity. There are a few related pieces:
 * - clientStatus: sets up the periodic requests to the http server to check for updates.
 * - ClientReleasesService: loaded super early, so that we can handle critical updates even on eg
 *   the prejoin screen. But that means t() isn't available when this file is imported, so we can't
 *   directly create notifications from here.
 * - ClientReleasesNotificationService: shows notifications to the user when an update comes in
 *   while in app. Loads later, so it can use t().
 */
export class ClientReleasesService extends GatherEventEmitter<
  ClientReleaseEvent,
  ClientReleaseEventDataMap
> {
  applyBrowserUpdateTimeout?: NodeJS.Timeout
  applyDesktopUpdateTimeout?: NodeJS.Timeout

  static getInstance = lazily(() => new ClientReleasesService())

  constructor() {
    super()
  }

  public start() {
    const cleanupCallbacks = [
      ElectronInterop?.onDesktopUpdate
        ? ElectronInterop?.onDesktopUpdate((info) => {
            this.handleDesktopClientUpdate(info)
          })
        : () => {},
      // TODO move http polling from clientStatus.ts to here
    ]
    return () => {
      cleanupCallbacks.forEach((f) => f())
    }
  }

  applyDesktopUpdate = () => {
    clearTimeout(this.applyDesktopUpdateTimeout)
    this.applyDesktopUpdateTimeout = undefined

    Logger.debug(`Updating desktop app`)
    // TODO(PLAT-3027) hook up RefreshTrackingController if we add it in v2
    // RefreshTrackingController.markImminentRefreshReason(RefreshReason.DesktopUpdate)
    ElectronInterop?.sendUpdateNow?.()
  }

  applyBrowserUpdate = () => {
    Logger.debug(`Updating browser client`)
    clearTimeout(this.applyBrowserUpdateTimeout)
    this.applyBrowserUpdateTimeout = undefined

    // TODO(PLAT-3027) hook up RefreshTrackingController if we add it in v2
    // RefreshTrackingController.markImminentRefreshReason(RefreshReason.WebUpdate)
    window.location.reload()
  }

  handleBrowserClientUpdate(info: BrowserUpdateInfo) {
    if (!info.broken && !info.outdated) return

    Logger.debug(
      `[Browser Client Status] Server told us our client is ${
        info.broken ? "broken" : "outdated"
      }!`,
      info,
    )

    if (info.level === ClientReleasePriority.CRITICAL) {
      // TODO(PLAT-3028): we'd really like to check whether we're in a state where we can refresh
      //  immediately eg on prejoin screen, loading, etc
      if (isInSpace()) {
        this.publishEvent(ClientReleaseEvent.BrowserUpdate, info)
        this.applyBrowserUpdateTimeout = setTimeout(this.applyBrowserUpdate, CRITICAL_NOTIF_TIME_MS)
      } else {
        // if we're not in space, we can refresh immediately
        this.applyBrowserUpdate()
      }
    } else if (isNotifiedClientUpdate(info.level)) {
      // Don't prompt for updates under a certain threshold of criticality
      if (canApplyUpdateImmediately(info.level)) {
        this.applyBrowserUpdate()
        return
      } else {
        // TODO(PLAT-3016) notify user about non-critical updates
        Logger.debug(`Non-critical browser update available. Prompting not implemented yet.`)
      }
    }
  }

  public handleDesktopClientUpdate(info: DesktopUpdateInfo) {
    Logger.log(`[Desktop Client Status] Desktop app requested to update`, info)
    if (isDefaultClientUpdate(info.level) || isNotifiedClientUpdate(info.level)) {
      if (!isDismissableClientUpdate(info.level)) {
        this.applyDesktopUpdateTimeout = setTimeout(this.applyDesktopUpdate, 60_000)
      }
      this.publishEvent(ClientReleaseEvent.DesktopUpdate, info)
    } else {
      // Don't prompt for updates under a certain threshold of criticality
      if (canApplyUpdateImmediately(info.level)) {
        this.applyDesktopUpdate()
        return
      }
    }
  }

  // Wrap PAGE_LOAD_TIME in a function for easy mocking in tests
  getPageLoadTime = () => PAGE_LOAD_TIME
}
