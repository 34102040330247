import "react" // TODO [Rebuild] remove once upgraded past TS 5.3.3

import { css, keyframes } from "@emotion/react"
import styled from "@emotion/styled"

import { isNil } from "gather-common-including-video/dist/src/public/fpHelpers"
import { media } from "src/styles/breakpoints/MediaQuery"

const SCROLLBAR_WIDTH = 8

// NOTE: If we ever decide to try adding padding to our scrollbar thumbs, see this PR for how to do so: https://github.com/gathertown/gather-town/pull/11725
// When first implemented, it proved to break too many existing scrollable containers, so a larger effort is necessary — see here for details: https://linear.app/gather-town/issue/APP-1420#comment-405ed26e
export const styledScrollbar = css`
  /* Non-Firefox browsers (they don't have the properties above, so they need vendor prefixes) */

  ::-webkit-scrollbar {
    width: ${SCROLLBAR_WIDTH}px;
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3);
    border-radius: 1000px;

    /* Note that this is in the non-Firefox browsers section — Firefox doesn't have support to change the scrollbar color on hover 😕 */

    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  /* Firefox browsers */
  scrollbar-color: rgba(255, 255, 255, 0.3);
  scrollbar-width: ${SCROLLBAR_WIDTH}px;
`

export const underline = css`
  text-decoration: underline;
`

export const textOverflowEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

const modalShadowValue = "0px 10px 25px rgba(0, 0, 0, 0.55)"

export const modalShadow = css`
  box-shadow: ${modalShadowValue};
`

export const unstyledButton = css`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-family: "DM Sans", sans-serif;
`

const spinnerKeyframes = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
`

export const spinningAnimationLinear = css`
  animation: ${spinnerKeyframes} 1.5s linear infinite;
`

export const blueGradientBackground = css`
  ${media.below("sm")`
    background: linear-gradient(180deg, #202540 0%, #30226b 100%);
  `}
  ${media.above("sm")`
    background: linear-gradient(180deg, #1e2552 20.28%, #382d77 89.8%);
  `}
`

export const pixelatedImage = css`
  image-rendering: pixelated;
  image-rendering: crisp-edges;
`

const rotationKeyframes = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
`

export const StyledRotatingLoaderContainer = styled.img<{ width?: string }>`
  width: ${({ width }) => (isNil(width) ? "70px" : width)};
  animation: ${rotationKeyframes} 1s infinite ease;
`

export const gradientLavenderBackground = "linear-gradient(180deg, #000 0%, #333A64 100%)"
