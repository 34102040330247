import { DateTime } from "luxon"

import { getCurrentSpaceIdOrNull } from "gather-browser-common/dist/src/public/spaceIdUtils"
import {
  CLIENT_REFRESH_CHECK_INTERVAL_MS,
  CLIENT_REFRESH_SERVER_POLLING_INTERVAL_MS,
} from "gather-client-common/dist/src/public/clientStatusUtils"
import { auth } from "gather-firebase-client/dist/src/public/firebase"
import { getBrowserRelease } from "src/api/releases"
import { buildErrorContext } from "src/utils/console"
import { Logger } from "src/utils/Logger"
import { ClientReleasesService } from "src/v2/services/ClientReleasesService"

let lastServerRequestTime = DateTime.fromMillis(0)

/**
 * Begins polling the client status HTTP endpoint to periodically check if we need to prompt the user to update.
 */
export const monitorClientStatus = () => {
  const interval = setInterval(async () => {
    // If not enough time has elapsed since our last server request, do nothing.
    if (
      DateTime.now().minus({ milliseconds: CLIENT_REFRESH_SERVER_POLLING_INTERVAL_MS }) <
      lastServerRequestTime
    ) {
      return
    }

    lastServerRequestTime = DateTime.now()

    // Ask the server about our client status.
    try {
      const userId = auth.currentUser?.uid ?? null
      const spaceId = getCurrentSpaceIdOrNull()

      const res = await getBrowserRelease(userId, spaceId)

      if (res) {
        ClientReleasesService.getInstance().handleBrowserClientUpdate(res)
      }
    } catch (e) {
      // Failure isn't a big deal because we run this check frequently - could be a network blip, etc.
      Logger.warn("[Client Status] Check failed!", buildErrorContext(e))
    }
  }, CLIENT_REFRESH_CHECK_INTERVAL_MS)

  return () => {
    clearInterval(interval)
  }
}
