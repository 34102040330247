import { Settings } from "luxon";

import {
  getNavigatorLanguage,
  getSelectedLanguageSetting } from
"gather-browser-common/dist/src/public/i18n";
import {
  LocalPreferenceKeys,
  localPreferences } from
"gather-browser-common/dist/src/public/LocalPreferences";
import { logMetricsEventUnsafe } from "gather-browser-common/dist/src/public/metrics/utils";
import { DateTimeExt } from "gather-common/dist/src/public/valueObjects/DateTimeExt";
import { DesktopSetting } from "gather-electron-interop/dist/src/public/desktopSettings";
import { switchEnv } from "gather-env-config/dist/src/public/env";
import {
  MetricsEventName,
  SpaceEventProperties } from
"gather-http-common/dist/src/public/metrics/constants";
import {
  BROWSER_SUPPORTED_LOCALES,
  DEFAULT_LOCALE,
  enablePseudoLocalization } from
"gather-i18n/dist/src/public/utils/constants";
import { localeMatcher } from "gather-i18n/dist/src/public/utils/utils";
import { updateUserData } from "src/api/users";
import { Logger } from "src/utils/Logger";
import ElectronInterop from "../ElectronInterop";
import t, { tAsString } from "./t";

const selectedLanguage = getSelectedLanguageSetting();
const navigatorLanguage = getNavigatorLanguage();

// Given a set of locales application has translations for and the set of locales a user requests, find the best matching locales.
const matchedLocale = (locale: string) =>
localeMatcher([locale], BROWSER_SUPPORTED_LOCALES, DEFAULT_LOCALE);

export const updateLocalSelectedLanguage = (option: string) => {
  Settings.defaultLocale = option;
  localPreferences.set(LocalPreferenceKeys.SelectedLanguage, option);
  ElectronInterop?.updateDesktopSetting?.(DesktopSetting.selectedLanguage, option);
};

/**
 * Switch the preferred language for the user
 * @param option the language code to switch to
 * @param switchLanguageSource which action was performed to switch
 * @param persist whether to persist this setting on the user record
 */
const switchPreferredLanguage = async (
option: string,
switchLanguageSource: SpaceEventProperties["switchLanguageSource"],
persist = true) =>
{
  if (persist) {
    await updateUserData({ selectedLanguage: option });
  }
  updateLocalSelectedLanguage(option);

  // We don't want to log Automatic changes to Amplitude
  if (switchLanguageSource !== "Automatic") {
    logMetricsEventUnsafe(MetricsEventName.SETTING_CHANGED, {
      settingName: "Language",
      selectedLanguage: option,
      navigatorLanguage,
      autoSwitch: false,
      switchLanguageSource
    });
  }
};

// This should be consistent with getSelectedOrRawLocale in gather-browser-common/src/i18n
const initLocale = () => {
  if (selectedLanguage) return matchedLocale(selectedLanguage);

  // Automatically switch to supported languages, does not persist it yet
  if (navigatorLanguage) {
    const matchedNavigatorLanguage = matchedLocale(navigatorLanguage);
    try {
      switchPreferredLanguage(matchedNavigatorLanguage, "Automatic", false);
    } catch (error) {
      Logger.error("Unable to persist automatically switched language");
    }
    return matchedNavigatorLanguage;
  }

  return DEFAULT_LOCALE;
};

export const getLocale = () =>
switchEnv({
  test: () => enablePseudoLocalization ? initLocale() : DEFAULT_LOCALE,
  local: initLocale,
  dev: initLocale,
  staging: initLocale,
  prod: initLocale
});

export const locale = getLocale();

export const localeIso = locale.split("-")[0];

// Setup `Luxon` early so to cover all instances
Settings.defaultLocale = locale;

type ToRelativeOptions = NonNullable<Parameters<DateTimeExt["toRelative"]>[0]>;

export function getTimeAgo(
dateTime: DateTimeExt,
{
  base = DateTimeExt.now(),
  nowText = tAsString(t("Now")),
  style,
  ...forwardedProps




}: {base?: DateTimeExt;nowText?: string;style?: ToRelativeOptions["style"] | "shortUnit";} & Omit<ToRelativeOptions, "base" | "style"> = {})
{
  const diffFromNow = dateTime.diff(base, "milliseconds");

  const diff = Math.abs(diffFromNow.milliseconds);
  const JUST_NOW_MILLISECONDS = 10_000;
  if (diff > JUST_NOW_MILLISECONDS && diff < 60_000) return tAsString(t("bd25394"));
  if (diff < JUST_NOW_MILLISECONDS) return nowText;

  if (style === "shortUnit") {
    const relativeText = dateTime.toRelative({
      base: base.toLuxonDateTime(),
      ...forwardedProps
    });
    if (relativeText && dateTime.toLuxonDateTime().locale?.startsWith("en"))
      // TODO [CA-2490]: would have to test against different locals, does the unit change?
      return relativeText.
      replace(" second", "s").
      replace(" seconds", "s").
      replace(" minutes", "m").
      replace(" minute", "m").
      replace(" hour", "h").
      replace(" hours", "h").
      replace(" day", "d").
      replace(" days", "d");

    return relativeText;
  }

  return dateTime.toRelative({
    base: base.toLuxonDateTime(),
    ...forwardedProps
  });
}