import { addToGlobalWindowForLocalDebugging } from "gather-browser-common/dist/src/public/addToGlobalWindowForLocalDebugging"
import { Uuid } from "gather-common-including-video/dist/src/public/uuid"
import type { SpaceClient } from "gather-game-client-v2/dist/src/public/SpaceClient"
import { GameLogicTestClient } from "gather-game-logic/dist/src/public/framework/GameLogicBuilder/GameLogicTestDriver"
import { ConnectionTarget } from "gather-game-logic/dist/src/public/models/ConnectionTarget"
import { ClientGameLogic } from "src/v2/gameLogic/clientGameLogic"
import type { ExtendedReposType } from "src/v2/repositories/ReposBuilder"

const GLOBAL_GATHER_DEV_REPOS_KEY = "Repos"

// Currently only used by tests since we normally don't reset the repos global
export const resetReposOnGlobalObject = () =>
  addToGlobalWindowForLocalDebugging({ [GLOBAL_GATHER_DEV_REPOS_KEY]: {} })

// Simple helper to encapsulate getting the data from where we make it accessible on the global
// window in a hacky way. See notes on `Repos` definition below.
export const getReposOnGlobalObject = (): ExtendedReposType =>
  // Assumption: Repos are on 'gatherDev'. It may be that we actually
  // don't create 'gatherDev' in the future (or only for local!) so
  // we may want a different convention for this particular object.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
  (globalThis as any).gatherDev[GLOBAL_GATHER_DEV_REPOS_KEY] as ExtendedReposType

resetReposOnGlobalObject()

// This is the top-level object that we reference to access data. When we say `Repos.Floor.[...]`,
// we're accessing this object. It refers to an object that we store on the `window`, which we then
// mutate to add repos.
//
// We do the indirection through the global window to avoid importing this file (`Repos.ts`) into
// other files that use it. This file imports the individual repo definitions, which sometimes need
// to refer to other repos. If the repo definitions in turn imported `Repos.ts`, we'd have a
// circular dependency. By using the global window, we can access the object without using an
// import statement. It's basically a hack, but gives us what we need.
//
// Some more historic context: https://github.com/gathertown/gather-town-v2/pull/2071, when we used
// to use a proxy object.
//
// We switched off using a proxy because it was applying a non-trivial performance tax for every
// access of `Repos.[...]` in the app. The new approach feels hackier, but it's optimized for
// performance after we established it was making a meaningful performance.
export const Repos: ExtendedReposType = getReposOnGlobalObject()

// This initially only has the global repos. Later, when the space loads, it will merge its
// game space state repos into this object. This allows us to say `Repos.floor` instead of
// `Repos.gameSpace.state.Floor`.
// We pass in `reposCollection` so that we can more easily dependency-inject behavior in tests.
// In regular app code, it'll be the global repos object.
export const hoistGameStateReposToTopLevelRepos = (
  state: SpaceClient<ClientGameLogic>["state"],
  reposCollection: ExtendedReposType,
) => {
  Object.assign(reposCollection, state)
}

/**
 * This is a test-only function to allow specifying an alternate driver
 * for the mocked 'Repos' used in unit tests. It is provided so that the
 * jest mock is properly typed.
 * You most likely don't need to call this directly in your test. Use TestSetupHarness.
 */
export const __testOnlyInitializeGameSpaceRepoWithDependencies = (_params: {
  testClient: GameLogicTestClient<ClientGameLogic>
  spaceId: Uuid
  connectionTarget: ConnectionTarget
  authUserId: string
}) => {
  throw new Error("This is a test-only function, see __mocks__/Repos.ts for implementation")
}
