import { AuthManagerInstance } from "gather-auth-client/dist/src/public/auth"
import { gate } from "gather-browser-common/dist/src/public/gatekeeping/gate"
import { HttpV2ClientBrowser } from "gather-browser-common/dist/src/public/http-client/httpV2Client"
import { amplitudeTransport } from "gather-browser-common/dist/src/public/metrics/utils"
import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager"
import { AVStrategyMap, registerGlobals } from "gather-video-client/dist/src/public/Globals"
import { RTCStatsManager } from "gather-video-client/dist/src/public/metrics/RTCStatsManager"
import { RemoteParticipantManager } from "gather-video-client/dist/src/public/RemoteParticipantManager"
import { GatherPeerManager } from "gather-video-client/dist/src/public/strategies/gather/GatherPeerManager"

const avStrategyMap: AVStrategyMap = {
  base: () => undefined,
  gather: (statsManager: RTCStatsManager, participantManager: RemoteParticipantManager) =>
    new GatherPeerManager(statsManager, participantManager),
}

// TODO [Rebuild] take out AV strategy system
// noinspection JSUnusedGlobalSymbols
const gatherVideoClientDataSource = {
  gate,
  getAVStrategyMap: () => avStrategyMap,
  performance: () => performance,
}

export const registerGatherVideoClientGlobals = () =>
  registerGlobals({
    HttpV2Client: HttpV2ClientBrowser,
    authTokenManager: AuthManagerInstance,
    metricsManager: newRelicManager,
    eventsManager: { addEvent: amplitudeTransport.logMetricsEvent.bind(amplitudeTransport) },
    gatherVideoClientDataSource,
  })

// example SFU URL
// wss://ip-10-205-106-64.us-west-1-a.prod.aws.gather.town:443
export const isAwsSfu = (serverUrl: string) =>
  serverUrl.startsWith("wss://ip-") && serverUrl.replace(":443", "").endsWith("aws.gather.town")

// returns { ip: 10.205.106.64, region: us-west-1-a}
export const getSfuFromUrl = (serverUrl: string) => {
  const sfuUrlElements = serverUrl.replace("wss://", "").split(".")
  const ip = sfuUrlElements[0]?.replace("ip-", "")?.replace(/-/g, ".") ?? "N/A"
  const region = sfuUrlElements[1] ?? "N/A"
  return { ip, region }
}

// example Global Accelerator SFU URL:
// wss://sfu.us-east-1-a.prod.aws.gather.town:443/ip-10-205-1-10
export const isAwsGaSfu = (serverUrl: string) =>
  serverUrl.startsWith("wss://sfu.") &&
  serverUrl.replace(":443", "").includes("aws.gather.town/ip-")

// returns {ip: 10.205.106.64, region: us-east-1-a [GA]}
export const getSfuFromGaUrl = (serverUrl: string) => {
  const sfuUrlElements = serverUrl.replace("wss://", "").split("/")
  const dnsElements = sfuUrlElements[0]?.split(".") ?? []
  const ip = sfuUrlElements[1]?.replace("ip-", "").replace(/-/g, ".") ?? "N/A"
  const region = dnsElements[1]?.concat(" [GA]") ?? "N/A"
  return { ip, region }
}
