import { newRelicManager } from "gather-browser-newrelic-manager/dist/src/public/newRelicManager"
import { Logger } from "src/utils/Logger"

export enum MetricName {
  AppLoaded = "app-loaded",
  UserIdLoaded = "user-id-loaded",
}

// https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark
export function markMetricStart(metricName: MetricName) {
  clearMetricMeasurements(metricName)
  performance.mark(`start-${metricName}`)
}

export function markMetricEnd(metricName: MetricName) {
  performance.mark(`end-${metricName}`)
}

function clearMetricMeasurements(metricName: MetricName) {
  performance.clearMarks(`start-${metricName}`)
  performance.clearMarks(`end-${metricName}`)
  performance.clearMeasures(metricName)
}

function reportMetricMeasurements(metricName: MetricName, entries: PerformanceEntryList) {
  newRelicManager.addPageAction(metricName, { duration: entries[0]?.duration ?? -1 })
}

// https://developer.mozilla.org/en-US/docs/Web/API/Performance/measure
export function measureMetric(
  metricName: MetricName,
  reportMeasurements = true,
  clearMeasurements = true,
  emitMissingEntriesWarning = false,
) {
  const hasStartEntries = performance.getEntriesByName(`start-${metricName}`).length > 0
  const hasEndEntries = performance.getEntriesByName(`end-${metricName}`).length > 0

  if (!hasStartEntries || !hasEndEntries) {
    if (emitMissingEntriesWarning) {
      Logger.warn("Missing performance entries for ", metricName)
    }

    return
  }

  performance.measure(metricName, `start-${metricName}`, `end-${metricName}`)
  const measurements = performance.getEntriesByName(metricName)

  if (reportMeasurements) {
    reportMetricMeasurements(metricName, measurements)
  }
  if (clearMeasurements) {
    clearMetricMeasurements(metricName)
  }
  return measurements
}
