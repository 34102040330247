import classnames from "classnames"
import { ReactNode } from "react"
import { isElectron } from "react-device-detect"

import { observer } from "gather-browser-common/dist/src/public/frontendMobxUtils"
import { backgroundStyle, preserveToolbarSpaceStyle } from "./LoadingFullScreenContainer.css"

type Props = {
  // We show this component at the beginning of the app's loading sequence before we have Repos,
  // Services, Redux, etc. Because of this, we can't yet render a DesktopToolbar component, but we
  // may still want to pretend one is there to prevent content from shifting when a toolbar is
  // present. Set preserveSpaceForDesktopToolbar to true to preserve this space on desktop.
  preserveSpaceForDesktopToolbar?: boolean
  children: ReactNode
}

export const LoadingFullScreenContainer = observer(function LoadingFullScreenContainer({
  children,
  preserveSpaceForDesktopToolbar = false,
}: Props) {
  return (
    <div
      className={classnames(backgroundStyle, {
        [preserveToolbarSpaceStyle]: preserveSpaceForDesktopToolbar && isElectron,
      })}
    >
      {children}
    </div>
  )
})
